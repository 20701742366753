<!--c系统订单配送单管理-->
<template>
    <div class="SystemOrderDelivery">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                    <el-col :span="5">
                        <el-form-item label="订单编码">
                            <el-input placeholder="" v-model="form.orderDeliveryCode" style="width: 215px" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never">
            <el-button type="primary" @click="handleQuery" size="small">查询</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                ref="table"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="订单编码" width="80" prop="orderCode" v-if="showColumn('orderCode')" />
                <el-table-column label="会员昵称" min-width="80" prop="memberName" v-if="showColumn('memberName')" />
                <el-table-column
                    label="会员手机号"
                    min-width="80"
                    prop="memberMobile"
                    v-if="showColumn('memberMobile')"
                />
                <el-table-column
                    label="部门名称"
                    min-width="150"
                    prop="payeeDeptName"
                    v-if="showColumn('payeeDeptName')"
                />
                <el-table-column
                    label="接单人"
                    min-width="80"
                    prop="orderReceiverName"
                    v-if="showColumn('orderReceiverName')"
                />
                <el-table-column
                    label="配送人"
                    min-width="80"
                    prop="orderDeliveryerName"
                    v-if="showColumn('orderDeliveryerName')"
                />
                <el-table-column label="订单号" min-width="100" prop="orderNo" v-if="showColumn('orderNo')" />
                <el-table-column label="创建时间" min-width="100" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column label="交易时间" min-width="100" prop="payTime" v-if="showColumn('payTime')" />
                <el-table-column label="付款方式" min-width="100" prop="payTypeName" v-if="showColumn('payTypeName')" />
                <el-table-column
                    label="收银交易编码"
                    min-width="100"
                    prop="cashTradeCode"
                    v-if="showColumn('cashTradeCode')"
                />
                <el-table-column
                    label="是否生成交易"
                    width="100"
                    prop="alreadyCreateTradeFlag"
                    v-if="showColumn('alreadyCreateTradeFlag')"
                    key="alreadyCreateTradeFlag"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.alreadyCreateTradeFlag | alreadyCreateTradeFlag }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="生成交易单时间"
                    width="100"
                    prop="updateTime"
                    v-if="showColumn('updateTime')"
                    key="updateTime"
                >
                    <template slot-scope="scope" v-if="scope.row.alreadyCreateTradeFlag">
                        <span>{{ scope.row.updateTime }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="商品总金额"
                    width="100"
                    prop="totalMoney"
                    v-if="showColumn('totalMoney')"
                    key="totalMoney"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.totalMoney | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="支付金额"
                    width="100"
                    prop="payMoney"
                    v-if="showColumn('payMoney')"
                    key="payMoney"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.payMoney | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="运费"
                    width="100"
                    prop="freightMoney"
                    v-if="showColumn('freightMoney')"
                    key="freightMoney"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.freightMoney | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="90"
                    header-align="center"
                    v-if="showColumn('operate')"
                    key="operate"
                    prop="operate"
                >
                    <!--                    v-if='hasPrivilege("menu.sale.selfRefund.open")'-->
                    <template slot-scope="scope">
                        <el-button size="mini" @click="rowDetail(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import MoneyUtils from 'js/MoneyUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';

export default {
    name: 'SystemOrderDelivery',
    components: {
        EfDeptTypeAndDeptSelect,
    },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            that: this,
            tableData: [],
            orderCodeList: [],
            form: {
                orderDeliveryCode: '',
                deptCode: '',
                page: 1,
                limit: Util.Limit,
            },
            meta: {
                payTypeAll: [],
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            url: {
                createCashTrade: '/fromSystemc/orderDelivery/createCashTrade',
                queryPayType: '/finance/pay/payType/page',
                page: '/fromSystemc/orderDelivery/queryOrderDeliveryList',
            },
        };
    },
    mounted() {},
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        alreadyCreateTradeFlag(alreadyCreateTradeFlag) {
            if (typeof alreadyCreateTradeFlag == 'undefined') {
                return '';
            }
            if (alreadyCreateTradeFlag == true) {
                return '已生成';
            }
            if (alreadyCreateTradeFlag == false) {
                return '未生成';
            }
        },
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
    },
    methods: {
        setDept(deptCode) {
            this.form.deptCode = deptCode;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
            if (deptType) {
                this.handleQuery();
            }
        },
        rowDetail(row) {
            Util.nameJump(
                this,
                'menu.sale.systemcOrderDelivery.detail',
                ['销售管理', 'c系统订单配送单', 'c系统订单配送单详情'],
                { form: row }
            );
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
    },
};
</script>

<style scoped>
.Order .el-form-item {
    margin-bottom: 0;
}
</style>
